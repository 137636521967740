<template>
    <el-container class="page-container">
        <el-header class="page-header" style="min-height: 60px;height: auto">
            <el-form label-position='left' ref="searchForm">
                <el-row :gutter="20">
                    <el-col :xs="12" :sm="12" :md="8" :lg="6">
                        <el-form-item prop="type" label="日志类型" label-width="85px">
                            <el-select v-model="pageForm.searchType" class="inner-input" placeholder="请选择日志类型"
                                       size="medium">
                                <el-option
                                        v-for="item in options"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="12" :sm="12" :md="8" :lg="6">
                        <el-form-item prop="status">
                            <el-date-picker
                                    class="inner-input"
                                    v-model="searchDate"
                                    type="datetimerange"
                                    :picker-options="pickerOptions"
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                    align="right"
                                    size="medium"
                                    :default-time="['00:00:00', '23:59:59']"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="12" :sm="12" :md="8" :lg="6">
                        <el-form-item prop="no" label="收款账号">
                            <el-input v-model="query.no" clearable placeholder="请输入收款账号" class="inner-input">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="12" :sm="12" :md="8" :lg="6">
                        <el-form-item prop="name" label="收款人姓名">
                            <el-input v-model="query.name" clearable placeholder="请输入收款人姓名" class="inner-input">
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="12" :sm="12" :md="8" :lg="6">
                        <el-form-item label-width="0">
                            <el-button type="primary" @click="handleSearch">
                                <i class="cw-icon cw-icon-sousuo"></i>搜索
                            </el-button>
                            <el-button type="primary" plain @click="clearSearch">
                                <i class="cw-icon cw-icon-zhongzhi"></i>重置
                            </el-button>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="12" :sm="12" :md="8" :lg="{span: 6, offset: 12}"
                            style="display: flex;justify-content: flex-end">
                        <el-button type="primary" @click="handleAdd">
                            <i class="cw-icon cw-icon-tianjia"></i>新增账户
                        </el-button>
                    </el-col>
                </el-row>
            </el-form>
        </el-header>
        <el-main class="page-main" style="padding: 0 20px">
            <!--<el-row class="row-btn-group">-->
            <!--<el-button type="primary" @click="handleAdd">-->
            <!--<i class="cw-icon cw-icon-tianjia"></i>新增账户-->
            <!--</el-button>-->
            <!--</el-row>-->
            <el-table
                    :data="tableData"
                    style="width: 100%">
                <el-table-column
                        prop="id"
                        label="编号"
                        width="90">
                </el-table-column>
                <el-table-column
                        prop="name"
                        label="收款人姓名"
                >
                </el-table-column>
                <el-table-column
                        prop="no"
                        label="收款账号"
                >
                </el-table-column>
                <!--                <el-table-column-->
                <!--                        prop="status"-->
                <!--                        label="收款人账号"-->
                <!--                >-->
                <!--                </el-table-column>-->
                <el-table-column
                        prop="type"
                        label="收款类型"
                        :formatter="typeformat"
                >
                </el-table-column>
                <el-table-column
                        label="状态"
                >
                    <template slot-scope="scope">
                        <el-switch
                                @change="handleChangeStatus(scope.row)"
                                v-model="scope.row.status"
                                :active-value="1"
                                :inactive-value="2"
                                active-color="#5ecc62"
                                inactive-color="#ebeef5">
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column
                        label="创建时间">
                    <template slot-scope="scope">
                        {{scope.row.createTime|formatDate}}
                    </template>
                </el-table-column>
                <el-table-column
                        prop="lastUpdateUser"
                        label="修改人"
                >
                </el-table-column>
                <el-table-column
                        label="修改时间"
                >
                    <template slot-scope="scope">
                        {{scope.row.lastUpdateTime|formatDate}}
                    </template>

                </el-table-column>
                <el-table-column
                        fixed="right"
                        width="180"
                        label="操作">
                    <template slot-scope="scope">
                        <el-button size="mini" type="success" @click="handleEdit(scope.row)" plain>编辑</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <br>
            <el-pagination
                    background
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageInfo.page"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="pageInfo.rows"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageInfo.total">
            </el-pagination>
        </el-main>
        <!--//编辑管理员-->
        <form-dialog @close="handleClose('addForm')" :title="editTitle" :dialogVisible="visibleFormDialog">
            <template slot="content">
                <el-form ref="addForm" :model="formData" :rules="rules" label-width="90px">
                    <el-form-item label="充值类型" prop="type">
                        <el-select v-model="formData.type" placeholder="请选择充值类型"
                                   class="inner-input">
                            <el-option
                                    v-for="item in paymentTypes"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>

                    </el-form-item>
                    <el-form-item label="账号" prop="no">
                        <el-input v-model="formData.no" class="inner-input"
                                  placeholder="请输入收款账号"></el-input>
                    </el-form-item>
                    <el-form-item label="姓名" prop="name">
                        <el-input v-model="formData.name" class="inner-input"
                                  placeholder="请输入姓名"></el-input>
                    </el-form-item>
                    <el-form-item label="开户行" prop="bankName">
                        <el-input v-model="formData.bankName" class="inner-input"
                                  placeholder="请输入开户行"></el-input>
                    </el-form-item>
                    <el-form-item label="开户地" prop="bankAddress">
                        <el-input v-model="formData.bankAddress" class="inner-input"
                                  placeholder="请输入开户地"></el-input>
                    </el-form-item>
                    <el-form-item label="二维码" prop="describe">
                        <el-upload
                                class="avatar-uploader"
                                :action="baseUrl"
                                :show-file-list="false"
                                :on-success="handleAvatarSuccess"
                                :before-upload="beforeAvatarUpload">
                            <img v-if="formData.describe" :src="formData.describe" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="状态" prop="status">
                        <el-radio-group v-model="formData.status">
                            <el-radio :label="1">启用</el-radio>
                            <el-radio :label="2">禁用</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <div class="flex flex-direction-row-reverse btn-group">
                        <el-button type="primary" @click="handleAddAccount">确 定</el-button>
                        <el-button @click="handleClose('addForm')">取 消</el-button>
                    </div>
                </el-form>
            </template>
        </form-dialog>
    </el-container>

</template>

<script>
    import {mapGetters} from 'vuex'

    export default {
        name: "logs",
        data() {
            return {
                dialogImageUrl: '',
                dialogVisible: false,
                action: process.env.VUE_APP_BASEAPI + 'open/file/upload/image'
            };
        },
        computed: {
            ...mapGetters(['token']),

        },
        methods: {
            handleRemove(file, fileList) {
                console.log(file, fileList);
            },
            handleSuccess(res, file) {
                this.dialogImageUrl = URL.createObjectURL(file.raw);
            },
            handlePictureCardPreview(file) {
                this.dialogImageUrl = file.url;
                this.dialogVisible = true;
            }
        }
    }
</script>

<style lang="less">
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }

    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
</style>
